<template>
  <div>
    <div class="row no-gutters mx-n1">
      <div class="col-xl-8 col-md-7 col-12 px-2 py-lg-0 py-1">
        <div class="card">
          <div class="row p-2">
            <!-- Filter Category -->
            <div class="col-md-8">
              <b-nav pills class="nav-category custom-scroller">
                <b-nav-item
                  :active="navActive == null"
                  @click="navCategoryAllOnClick">
                  Semua
                </b-nav-item>
                <b-nav-item
                  v-for="(category, index) in medicineCategories"
                  :key="category.id"
                  :active="navActive == index"
                  @click="navCategoryOnClick(index, category.id)">
                  {{ category.name }}
                </b-nav-item>
              </b-nav>
            </div>

            <!-- Search By Name -->
            <div class="col-md-4">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text squared>
                    <b-icon-search></b-icon-search>
                  </b-input-group-text>
                </template>
                <b-form-input
                  type="text"
                  v-model="filter.search"
                  placeholder="Cari Obat"
                  @keyup="inputSearchOnKeyUp"></b-form-input>
              </b-input-group>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-5 col-12 px-2 py-lg-0 py-1">
        <div class="row no-gutters mx-n1">
          <div class="col-md py-lg-0 py-1 px-1">
            <b-button
              squared
              class="btn btn-lg btn-block btn-info modal-trigger text-center"
              v-b-modal.modal-patient>
              {{ display.patient_name }}
            </b-button>
          </div>
          <div class="col-md py-lg-0 py-1 px-1">
            <b-button
              squared
              class="btn btn-lg btn-block btn-info modal-trigger text-center"
              v-b-modal.modal-doctor>
              {{ display.doctor_name }}
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters mx-n1">
      <div
        class="col-xl-8 col-md-7 col-12 px-2"
        style="overflow-y: auto"
        ref="medicineScroller"
        @scroll="medicineOnScroll">
        <div class="row no-gutters mx-n1" style="height: calc(100vh - 415px)">
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 p-2"
            v-for="medicine in medicines"
            :key="medicine.id">
            <MedicineCard :data="medicine" @cardOnClick="cardMedicineOnClick" />
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-5 col-12 px-2">
        <div class="row no-gutters my-1 mx-n1">
          <div class="col-md px-1">
            <b-button
              class="btn btn-lg btn-block btn-info modal-trigger"
              squared
              @click="$bvModal.show('modal-prescription')">
              Pilih Resep
            </b-button>
          </div>
        </div>
        <div class="row no-gutters my-1 mx-n1">
          <div class="col-md">
            <div class="card shadow-sm" style="min-height: calc(100vh - 415px)">
              <div
                class="card-body py-2 px-3"
                style="height: 100%; overflow-y: auto">
                <!-- items unavailable stock -->
                <div
                  class="list-group list-group-flush"
                  style="overflow: hidden">
                  <div
                    class="row py-1"
                    v-for="(item, index) in form.itemsUnavailable"
                    :key="item.medicine_id">
                    <div
                      class="col-md-12 d-flex justify-content-between align-items-center pb-0">
                      <div
                        class="text-truncate"
                        style="font-size: 13px; width: 100px">
                        {{ item.medicine_name }}
                      </div>
                      <div class="btn-group btn-group-sm">
                        <b-button
                          squared
                          class="btn btn-sm btn-info square"
                          @click="btnMinQtyOnClick(item, index)"><i class="fas fa-sm fa-fw fa-minus"></i>
                        </b-button>
                        <span>
                          <button
                            class="btn btn-sm btn-block bg-white btn-quantity">
                            {{ item.quantity }}
                          </button>
                        </span>
                        <b-button
                          squared
                          class="btn btn-sm btn-info"
                          @click="btnAddQtyOnClick(item, index)"><i class="fas fa-sm fa-fw fa-plus"></i>
                        </b-button>
                      </div>
                      <div class="text-right">
                        <div class="font-weight-bold">
                          {{
                            parseInt(
                              parseInt(item.amount) * parseInt(item.quantity)
                            ).toLocaleString("id-ID")
                          }}
                        </div>
                        <div v-if="item.promo_amount > 0">
                          <small>
                            -
                            {{
                              parseInt(
                                parseInt(item.promo_amount) *
                                parseInt(item.quantity)
                              ).toLocaleString("id-ID")
                            }}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 pt-1 pb-2">
                      <small class="text-danger">Sisa stok {{ item.medicine_name }} yang tersedia tidak
                        mencukupi</small>
                    </div>
                  </div>
                </div>

                <!-- items available stock -->
                <div class="list-group list-group-flush">
                  <div
                    class="d-flex justify-content-between align-items-center py-2"
                    v-for="(item, index) in form.items"
                    :key="item.medicine_id">
                    <div
                      class="text-truncate"
                      style="font-size: 13px; width: 100px">
                      {{ item.medicine_name }}
                    </div>
                    <div class="btn-group btn-group-sm">
                      <b-button
                        squared
                        class="btn btn-sm btn-info square"
                        @click="btnMinQtyOnClick(item, index)"><i class="fas fa-sm fa-fw fa-minus"></i></b-button>
                      <span>
                        <button
                          class="btn btn-sm btn-block bg-white btn-quantity"
                          @click="btnQtyOnClick(item, index)">
                          {{ item.quantity }}
                        </button>
                      </span>
                      <b-button
                        squared
                        class="btn btn-sm btn-info"
                        @click="btnAddQtyOnClick(item, index)"><i class="fas fa-sm fa-fw fa-plus"></i>
                      </b-button>
                    </div>
                    <div class="text-right">
                      <div class="font-weight-bold">
                        {{
                          parseInt(
                            parseInt(item.amount) * parseInt(item.quantity)
                          ).toLocaleString("id-ID")
                        }}
                      </div>
                      <div v-if="item.promo_amount > 0">
                        <small>
                          -
                          {{
                            parseInt(
                              parseInt(item.promo_amount) *
                              parseInt(item.quantity)
                            ).toLocaleString("id-ID")
                          }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer py-2 px-3 bg-white">
                <div class="d-flex justify-content-between">
                  <div>Subtotal</div>
                  <div class="font-weight-bold">
                    {{ parseInt(form.total_amount).toLocaleString("id-ID") }}
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div>Potongan Promo</div>
                  <div class="font-weight-bold">
                    {{
                      parseInt(form.total_promo_amount).toLocaleString("id-ID")
                    }}
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div>Total</div>
                  <div class="font-weight-bold h5 mb-0">
                    {{
                      parseInt(
                        form.final_amount + form.coupon_amount
                      ).toLocaleString("id-ID")
                    }}
                  </div>
                </div>
                <div class="d-flex">
                  <small class="text-danger" v-if="form.unavailable != []">
                    *Hanya item dengan stok tersedia yang dapat dibayar
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters mx-n1 mt-3">
      <div class="col-md px-2 offset-8">
        <div class="row no-gutters mx-n1">
          <div class="col-md-10 px-1">
            <button
              class="btn btn-lg btn-block btn-success"
              @click="btnCheckoutOnClick">
              <i class="fas fa-fw fa-cash-register"></i>Bayar
            </button>
          </div>
          <div class="col-md-2 px-1">
            <button
              class="btn btn-lg btn-block btn-outline-danger"
              @click="btnResetOnClick">
              <i class="fas fa-fw fa-sync-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-quantity" title="Jumlah Obat" @ok="handleQtyModal">
      <b-form-group id="input-group-1" label="Jumlah Obat:" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="modalQuantity.quantity"
          type="text"
          placeholder="Masukkan Jumlah Obat"></b-form-input>
      </b-form-group>
    </b-modal>

    <ModalCheckout
      :form="form"
      :coupon="coupon"
      :error="error"
      :isHospitalTransaction="false"
      @paymentMethodOnClick="paymentMethodOnClick"
      @btnShortCutOnClick="btnShortCutOnClick"
      @inputCashOnKeyUp="inputCashOnKeyUp"
      @btnEWalletOnClick="btnEWalletOnClick"
      @couponOnDismissed="couponOnDismissed"
      @modalCheckoutOnHide="modalCheckoutOnHide"
      @confirmCheckout="confirmCheckout" />

    <ModalPatient
      :isCashier="true"
      @chosenPatient="setPatient"
      @submitedPatient="setPatient"
      @customSubmitedPatient="customSubmitedPatient" />

    <ModalDoctor
      @chosenDoctor="setDoctor"
      @submitedDoctor="setDoctor"
      @customSubmitedDoctor="customSubmitedDoctor" />

    <ModalPrescription :source="1" @setSelectedItem="setSelectedItem" />

    <!-- Modal Coupon -->
    <ModalCoupon @chosenCoupon="modalCouponOnSelect" />
  </div>
</template>

<script>
// components
import Print from "@/component/pharmacy/pharmacy-orders/Print.vue";
import ModalCoupon from "@/component/general/ModalCoupon.vue";
import MedicineCard from "@/component/pharmacy/cashier/MedicineCard.vue";
import ModalPatient from "@/component/general/ModalPatient.vue";
import ModalPrescription from "@/component/general/ModalPrescription.vue";
import ModalCheckout from "@/component/general/ModalCheckout.vue";
import ModalDoctor from "@/component/general/ModalDoctor.vue";

// modules
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getPromoAmount } from "@/core/modules/Helper.js";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  components: {
    ModalCoupon,
    MedicineCard,
    ModalPatient,
    ModalCheckout,
    ModalDoctor,
    ModalPrescription,
    Print,
  },

  data() {
    return {
      // Display Data
      display: {
        patient_name: "Pilih Pasien",
        doctor_name: "Pilih Dokter",
      },
      // Medicines
      medicines: [],
      medicineCategories: [],
      // coupon
      coupon: null,
      // Form
      form: {
        doctor_id: "",
        doctor_name: "",
        patient_id: "",
        patient_name: "",
        prescription_id: 0,
        payment_method_id: 1,
        bank_id: "",
        coupon_id: null,
        coupon_name: "",
        coupon_is_percent: 0,
        coupon_percentage_amount: 0,
        coupon_amount: 0,
        card_number: "",
        card_type: "",
        edc_struct: "",
        e_wallet_id: "",
        surcharge: 0,
        total_amount: 0,
        total_promo_amount: 0,
        tax_amount: 0,
        final_amount: 0,
        payment_amount: 0,
        change_amount: 0,
        notes: "",
        items: [],
        itemsUnavailable: [],
      },
      error: {
        bank_id: "",
        card_number: "",
        card_type: "",
        edc_struct: "",
      },
      // Filter
      filter: {
        medicine_category_id: "",
        search: "",
      },
      // modal quantity
      modalQuantity: {
        index: "",
        item: [],
        quantity: 0,
        old_subtotal: 0
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRow: 0,
      // Print Data
      items: [],
      data: {},
      // Others
      navActive: null,
      prescriptData: [],
      prescriptId: "",
      dataDisplay: false,
    };
  },

  methods: {
    navCategoryAllOnClick() {
      this.navActive = null;
      this.filter.medicine_category_id = "";
      this.currentPage = 1;
      this.scrollMedicineToTop();
      this.getMedicine();
    },

    modalCouponOnSelect(value) {
      const finalAmountBeforeCoupon =
        this.form.final_amount + this.form.coupon_amount;
      const newCouponAmount = this.getCouponAmount(true, value);
      // validate coupon amount
      if (
        finalAmountBeforeCoupon <= 0 ||
        finalAmountBeforeCoupon < newCouponAmount
      ) {
        Swal.fire(
          "Pemberitahuan",
          "Kupon tidak boleh melebihi nominal transaksi",
          "warning"
        );
        return;
      }
      this.$bvModal.hide("modal-coupon");
      this.coupon = value;
      this.form.coupon_id = value.id;
      this.form.coupon_name = value.name;
      this.form.coupon_is_percent = value.is_percent;
      let couponPercentageAmount = 0;
      if (value.is_percent == 1) couponPercentageAmount = value.amount;
      this.form.coupon_percentage_amount = couponPercentageAmount;
      // restore original final amount
      this.form.final_amount = finalAmountBeforeCoupon;
      // set coupon amount
      this.form.coupon_amount = newCouponAmount;
      // re calculate final amount
      this.form.final_amount = this.form.final_amount - newCouponAmount;
    },

    getCouponAmount(processed = false, value = null) {
      let coupon = value ? value : this.coupon;
      let couponAmount = 0;
      if (coupon) {
        if (coupon.is_percent == 1) {
          if (!processed) couponAmount = `${parseInt(coupon.amount)}%`;
          else {
            couponAmount = parseInt(
              (this.form.final_amount + this.form.coupon_amount) *
              (coupon.amount / 100)
            );
            if (couponAmount > coupon.max_amount && coupon.max_amount > 0)
              couponAmount = coupon.max_amount;
          }
        } else couponAmount = parseInt(coupon.amount);
      }
      return couponAmount;
    },

    couponOnDismissed() {
      this.coupon = null;
      // reset final amount
      this.form.final_amount = this.form.final_amount + this.form.coupon_amount;
      // reset coupon
      this.form.coupon_id = null;
      this.form.coupon_name = "";
      this.form.coupon_is_percent = 0;
      this.form.coupon_percentage_amount = 0;
      this.form.coupon_amount = 0;
    },

    // set data from prescription
    async setSelectedItem(data, id) {
      // hide modal
      this.$bvModal.hide("modal-prescription");
      // set data form
      this.form.doctor_id = data.doctor_id;
      this.form.doctor_name = data.doctor_name;
      this.form.patient_id = data.patient_id;
      this.form.patient_name = data.patient_name;
      this.form.total_amount = 0;
      this.form.total_promo_amount = 0;
      this.form.final_amount = 0;
      data.unavailable.forEach((el) => {
        el.promo_amount = getPromoAmount(el.promo, el.amount);
      });
      data.available.forEach((el) => {
        this.setMedicineItemData(el, getPromoAmount(el.promo, el.amount));
      });
      // display
      let dr = data.doctor_name.split(" ");
      let patient = data.patient_name.split(" ");
      this.display.patient_name = patient[0];
      this.display.doctor_name = "dr." + dr[0];
      // get data prescription
      this.getDataPrescription(id)
    },

    async getDataPrescription(id) {
      let prescript = await module.get(`prescriptions/${id}`);
      this.prescriptData = prescript;
      this.prescriptId = id;
    },

    // UNUSED
    // setSelectedItemRoute(data, id) {
    //   // data.available.forEach((el) => {
    //   //   this.cardMedicineOnClick(el)
    //   // })
    //   // // set data form
    //   // this.form.items = data.available;
    //   // this.form.itemsUnavailable = data.unavailable;

    //   // this.form.doctor_id = data.doctor_id;
    //   // this.form.doctor_name = data.doctor_name;
    //   // this.form.patient_id = data.patient_id;
    //   // this.form.patient_name = data.patient_name;

    //   // this.form.total_amount = 0;
    //   // this.form.final_amount = 0;

    //   // data.available.forEach((el) => {
    //   //   this.form.total_amount += el.subtotal;
    //   //   this.form.final_amount += el.subtotal;
    //   // });

    //   // // display
    //   // this.display.patient_name = data.patient_name;
    //   // this.display.doctor_name = data.doctor_name;

    //   // // get data prescription
    //   // let prescript = await module.get(`prescriptions/${id}`);
    //   // this.prescriptData = prescript;
    //   // this.prescriptId = id
    // },

    setPatient(value) {
      this.form.patient_id = value.id;
      let name = value.name.split(" ");
      this.display.patient_name = `${name[0]}`;
      this.$bvModal.hide("modal-patient");
    },

    customSubmitedPatient(value) {
      this.form.patient_name = value;
      this.display.patient_name = `Pasien: ${value}`;
      this.$bvModal.hide("modal-patient");
    },

    setDoctor(value) {
      this.form.doctor_id = value.id;
      let name = value.name.split(" ");
      this.display.doctor_name = `dr. ${name[0]}`;
      this.$bvModal.hide("modal-doctor");
    },

    customSubmitedDoctor(value) {
      this.form.doctor_name = value;
      this.display.doctor_name = `Dokter: ${value}`;
      this.$bvModal.hide("modal-doctor");
    },

    resetForm() {
      this.form = validation.clearForm(this.form);
      this.couponOnDismissed();
      this.form.items = [];
      this.form.payment_method_id = 1;
      this.form.total_amount = 0;
      this.form.total_promo_amount = 0;
      this.form.final_amount = 0;
      this.form.change_amount = 0;
      this.form.tax_amount = 0;
      this.form.surcharge = 0;
      this.display.patient_name = "Pilih Pasien";
      this.display.doctor_name = "Pilih Dokter";
      this.data = {};
      this.items = [];
      this.error = validation.clearValidationError(this.error);
    },

    btnResetOnClick() {
      Swal.fire({
        title: "Konfirmasi",
        text: "Reset Order?",
        showCancelButton: true,
        icon: "question",
      }).then((result) => {
        if (result.value) {
          this.resetForm();
          window.data = [];
        }
      });
    },

    scrollMedicineToTop() {
      this.$refs.medicineScroller.scrollTo({ top: 0, behavior: "smooth" });
    },

    async getMedicine(isScrolled = false) {
      let slug = `?medicine_category_id=${this.filter.medicine_category_id}&search=${this.filter.search}&with_promo=true`;
      let paging = `&paginate=true&page=${this.currentPage}&per_page=${this.perPage}`;
      const response = await axios.get(
        `medicines/get-for-cashier${slug}${paging}`
      );
      if (response.status == 200) {
        const result = response.data;
        // set total row
        if (this.totalRow == 0) this.totalRow = result.total_row;
        // set medicine
        if (isScrolled) {
          if (result.data.length < this.totalRow)
            this.medicines = this.medicines.concat(result.data);
        } else {
          this.medicines = result.data;
        }
      }
    },

    async getMedicineCategories() {
      this.medicineCategories = await module.list("medicine-categories");
    },

    navCategoryOnClick(index, id) {
      this.navActive = index;
      this.filter.medicine_category_id = id;
      this.currentPage = 1;
      this.scrollMedicineToTop();
      this.getMedicine();
    },

    inputSearchOnKeyUp() {
      debouncer(() => {
        this.getMedicine();
      }, 500);
    },

    medicineOnScroll(e) {
      let target = e.target;
      if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
        this.currentPage++;
        this.getMedicine(true);
      }
    },

    btnQtyOnClick(item, index) {

      let items = this.form.items,
        found = items[index],
        quantity = found.quantity;

      this.modalQuantity.item = found
      this.modalQuantity.index = index
      this.modalQuantity.quantity = quantity
      this.modalQuantity.old_subtotal = found.amount * found.quantity

      this.$bvModal.show('modal-quantity')

      // if (quantity >= found.stock) {
      //   Swal.fire({
      //     title: "Pemberitahuan",
      //     text: "Jumlah Melebihi Ketersediaan Stock!",
      //     icon: "warning",
      //     showConfirmButton: false,
      //     timer: 1800,
      //     timerProgressBar: true,
      //   });
      //   return;
      // }
      // found.quantity += 1;
      // found.subtotal = found.amount * found.quantity;
      // this.form.total_amount += parseInt(found.amount);
      // this.form.total_promo_amount += parseInt(found.promo_amount);
      // this.form.final_amount =
      //   this.form.total_amount - this.form.total_promo_amount;
    },

    handleQtyModal() {
      this.$bvModal.hide('modal-quantity')

      if (this.modalQuantity.quantity >= this.modalQuantity.stock) {
        Swal.fire({
          title: "Pemberitahuan",
          text: "Jumlah Melebihi Ketersediaan Stock!",
          icon: "warning",
          showConfirmButton: false,
          timer: 1800,
          timerProgressBar: true,
        });
        return;
      } else {
        console.log(this.form.items[this.modalQuantity.index]);
        this.form.items[this.modalQuantity.index].quantity = parseInt(this.modalQuantity.quantity)
        this.form.items[this.modalQuantity.index].subtotal = this.form.items[this.modalQuantity.index].amount * this.form.items[this.modalQuantity.index].quantity
        // found.quantity += 1;
        // found.subtotal = found.amount * found.quantity;
        this.form.total_amount -= parseInt(this.modalQuantity.old_subtotal);
        this.form.total_amount += parseInt(this.form.items[this.modalQuantity.index].subtotal);
        this.form.total_promo_amount += parseInt(this.form.items[this.modalQuantity.index].promo_amount);
        console.log(this.form.total_promo_amount, this.form.total_amount);
        this.form.final_amount = this.form.total_amount - this.form.total_promo_amount;
      }

    },

    btnAddQtyOnClick(item, index) {
      let items = this.form.items,
        found = items[index],
        quantity = found.quantity;
      if (quantity >= found.stock) {
        Swal.fire({
          title: "Pemberitahuan",
          text: "Jumlah Melebihi Ketersediaan Stock!",
          icon: "warning",
          showConfirmButton: false,
          timer: 1800,
          timerProgressBar: true,
        });
        return;
      }
      found.quantity += 1;
      found.subtotal = found.amount * found.quantity;
      this.form.total_amount += parseInt(found.amount);
      this.form.total_promo_amount += parseInt(found.promo_amount);
      this.form.final_amount =
        this.form.total_amount - this.form.total_promo_amount;
    },

    btnMinQtyOnClick(item, index) {
      let items = this.form.items,
        found = items[index],
        quantity = found.quantity;
      if (quantity == 1) {
        quantity = 0;
        items.splice(index, 1);
      } else {
        found.quantity -= 1;
        found.subtotal = found.amount * found.quantity;
      }
      this.form.total_amount -= parseInt(found.amount);
      this.form.total_promo_amount -= parseInt(found.promo_amount);
      this.form.final_amount =
        this.form.total_amount - this.form.total_promo_amount;
    },

    cardMedicineOnClick(item, promoAmount) {
      if (item.stock <= 0) {
        Swal.fire("Peringatan", "Stok Habis", "warning");
        return;
      }
      let exist = false,
        medicineId,
        quantity = 0,
        items = this.form.items;
      items.forEach((element) => {
        if (element.medicine_id == item.id) {
          medicineId = element.medicine_id;
          quantity = element.quantity;
          exist = true;
        }
      });
      if (exist) {
        let found = items.findIndex((x) => x.medicine_id == medicineId);
        if (items[found].quantity >= items[found].stock) {
          Swal.fire({
            title: "Pemberitahuan",
            text: "Jumlah Melebihi Ketersediaan Stock!",
            icon: "warning",
            showConfirmButton: false,
            timer: 1800,
            timerProgressBar: true,
          });
          return;
        }
        items[found].quantity = quantity += 1;
        items[found].subtotal = parseInt(quantity * items[found].amount);
        this.form.total_amount += parseInt(item.sales_price);
        this.form.total_promo_amount += parseInt(items[found].promo_amount);
        this.form.final_amount =
          this.form.total_amount -
          this.form.total_promo_amount +
          this.form.tax_amount;
      } else {
        items.push({
          medicine_id: item.id,
          promo_id: item.promo_id,
          medicine_name: item.name,
          quantity: 1,
          amount: item.sales_price,
          promo_amount: promoAmount,
          subtotal: item.sales_price,
          stock: item.stock,
          notes: "",
          promo: item.promo,
        });
        this.form.total_amount += parseInt(item.sales_price);
        this.form.total_promo_amount += parseInt(promoAmount);
        this.form.final_amount =
          this.form.total_amount -
          this.form.total_promo_amount +
          this.form.tax_amount;
      }
    },

    setMedicineItemData(item, promoAmount) {
      if (item.stock <= 0) {
        Swal.fire("Peringatan", `Stok ${item.medicine_name} habis`, "warning");
        return;
      }
      let exist = false,
        medicineId,
        quantity = 0,
        items = this.form.items;
      items.forEach((element) => {
        if (element.medicine_id == item.medicine_id) {
          medicineId = element.medicine_id;
          quantity = element.quantity;
          exist = true;
        }
      });
      if (exist) {
        let found = items.findIndex((x) => x.medicine_id == medicineId);
        if (items[found].quantity >= items[found].stock) {
          Swal.fire({
            title: "Pemberitahuan",
            text: "Jumlah Melebihi Ketersediaan Stock!",
            icon: "warning",
            showConfirmButton: false,
            timer: 1800,
            timerProgressBar: true,
          });
          return;
        }
        items[found].quantity = item.quantity;
        items[found].subtotal = parseInt(quantity * items[found].amount);
        this.form.total_amount += parseInt(item.amount);
        this.form.total_promo_amount += parseInt(items[found].promo_amount);
        this.form.final_amount =
          this.form.total_amount -
          this.form.total_promo_amount +
          this.form.tax_amount;
      } else {
        items.push({
          medicine_id: item.medicine_id,
          promo_id: item.promo ? item.promo.id : null,
          medicine_name: item.medicine_name,
          quantity: item.quantity,
          amount: item.amount,
          promo_amount: promoAmount,
          subtotal: item.amount,
          stock: item.stock,
          notes: "",
          promo: item.promo,
        });
        this.form.total_amount += parseInt(item.amount * item.quantity);
        this.form.total_promo_amount += parseInt(promoAmount * item.quantity);
        this.form.final_amount =
          this.form.total_amount -
          this.form.total_promo_amount +
          this.form.tax_amount;
      }
    },

    modalCheckoutOnHide() {
      this.error = validation.clearValidationError(this.error);
      this.couponOnDismissed();
      this.form.payment_amount = 0;
      this.form.change_amount = 0;
    },

    paymentMethodOnClick(id) {
      this.form.notes = "";
      this.form.payment_method_id = id;
    },

    btnCheckoutOnClick() {
      if (this.form.items.length == 0) {
        Swal.fire({
          title: "Peringatan",
          text: "Anda Belum Memilih Obat",
          icon: "warning",
        });
        return;
      }
      this.$bvModal.show("modal-checkout");
    },

    btnShortCutOnClick(amount) {
      if (amount != 0) {
        this.form.payment_amount = parseInt(amount).toLocaleString("id-ID");
        this.form.change_amount =
          parseInt(this.form.payment_amount.toString().replace(/\D/g, ""), 10) -
          this.form.final_amount;
      } else {
        this.form.payment_amount = parseInt(
          this.form.final_amount
        ).toLocaleString("id-ID");
        this.form.change_amount = 0;
      }
    },

    btnEWalletOnClick(id) {
      this.form.e_wallet_id = id;
    },

    inputCashOnKeyUp() {
      this.form.change_amount =
        parseInt(this.form.payment_amount.toString().replace(/\D/g, ""), 10) -
        this.form.final_amount;
    },

    async confirmCheckout() {
      if (
        parseInt(this.form.payment_amount.toString().replace(/\D/g, ""), 10) <
        this.form.final_amount &&
        this.form.payment_method_id == 1
      ) {
        Swal.fire({
          title: "Peringatan",
          text: "Uang Tidak Cukup",
          icon: "warning",
        });
      } else {
        let response = await module.submit(
          this.form,
          "pharmacy-orders/checkout"
        );
        if (response.state == "error") {
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          if (
            typeof this.prescriptId != "undefined" &&
            this.prescriptId != null &&
            this.prescriptId != ""
          ) {
            // update data redeem status prescription
            this.prescriptData.redeem_status = 1;
            this.prescriptData._method = "put";

            let res = await module.submit(
              this.prescriptData,
              `prescriptions/updateRedeem/${this.prescriptId}`
            );
          }

          Swal.fire({
            title: response.success.title,
            text: response.success.message,
            icon: "success",
          }).then((result) => {
            if (result.value) {
              this.$router.push(
                `/pharmacy/sales/detail/${response.success.order.order_id}`
              );
            }
          });
        }
      }
    },
  },

  // watch:{
  //   form : ()=>{
  //     this.form.items.splice(this.form.items.length)
  //     this.form.itemsUnavailable.splice(this.form.itemsUnavailable.length)
  //   }
  // },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Apotek", route: "" },
      { title: "Tambah Penjualan" },
    ]);
    // get data
    this.getMedicineCategories();
    this.getMedicine();

    // set data form prescriptions
    if (
      typeof window.data != "undefined" &&
      window.data != null &&
      window.data != []
    ) {
      let arr = [];
      arr = window.data;

      // set form prescription_id
      this.form.prescription_id = window.id

      // push item into form selected item
      arr.available.forEach((el) => {
        this.setMedicineItemData(el, getPromoAmount(el.promo, el.amount));
      });

      // set patient data
      this.form.patient_id = arr.patient_id;
      this.form.patient_name = arr.patient_name
        ? arr.patient_name
        : "Pilih Pasien";
      this.display.patient_name = arr.patient_name
        ? arr.patient_name
        : "Pilih Pasien";

      // set doctor
      this.form.doctor_id = arr.doctor_id;
      this.form.doctor_name = arr.doctor_name
        ? arr.doctor_name
        : "Pilih Dokter";
      this.display.doctor_name = arr.doctor_name
        ? arr.doctor_name
        : "Pilih Dokter";

      // set prescription id
      this.getDataPrescription(window.id);

      // reset global data
      window.data = [];
    }
  },
};
</script>

<style scoped>
/* width */
.nav-category::-webkit-scrollbar {
  width: 3px !important;
  height: 5px !important;
}

/* Track */
.custom-scroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(221, 221, 221) !important;
  border-radius: 10px !important;
}

/* Handle */
.custom-scroller::-webkit-scrollbar-thumb {
  background: #0bb7af !important;
  border-radius: 10px !important;
}

/* Handle on hover */
.custom-scroller::-webkit-scrollbar-thumb:hover {
  background: #0bb7af !important;
}

.nav-category {
  display: -webkit-box;
  white-space: nowrap !important;
  overflow-x: auto !important;
  scrollbar-width: none;
}

.custom-scroller {
  display: -webkit-box;
  white-space: nowrap !important;
  overflow-x: auto !important;
  scrollbar-width: none;
}

.modal-trigger {
  height: 54.23px;
  font-size: 13px;
}

.nav-category li {
  display: inline-block !important;
  float: none !important;
}

.btn-quantity {
  min-width: 35px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #305b8f !important;
}

/* Handle on hover */
</style>
